import React, { useEffect, useState } from "react";

function Navbar({ refProp, children }) {
  const { hourlyRef, dailyRef } = refProp;

  const currentTheme = localStorage.getItem("theme") || "winter";

  const [theme, setTheme] = useState(currentTheme);
  const [darkIcon, setDarkIcon] = useState("moon");

  useEffect(() => {
    if (currentTheme === "winter") {
      setDarkIcon("moon");
    } else {
      setDarkIcon("sun");
    }
  }, []);

  const themeToggle = () => {
    return theme === "winter" ? "dark" : "winter";
  };

  const themeChange = () => {
    setTheme(() => {
      localStorage.setItem("theme", themeToggle());
      return themeToggle();
    });
  };

  const gotoHourly = () => hourlyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const gotoDaily = () => dailyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return (
    <div className="drawer" data-theme={theme}>
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        {/* <!-- Navbar --> */}
        <div className="w-full navbar bg-base-100 px-6 shadow-md z-10">
          <div className="flex-none lg:hidden">
            <label htmlFor="my-drawer-3" className="btn btn-square btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>
          <div className="flex-1 px-2">
            <a className="btn btn-ghost normal-case text-xl">Weather App</a>
          </div>

          <div className="flex-none hidden lg:block">
            <ul className="menu menu-horizontal p-2">
              <li>
                <a onClick={gotoHourly}>Hourly</a>
              </li>
              <li>
                <a onClick={gotoDaily}>7-Day</a>
              </li>
            </ul>
          </div>
          <label className="swap swap-rotate">
            <input type="checkbox" onClick={themeChange} />

            <svg
              className={
                (darkIcon === "moon" ? "swap-on" : "swap-off") +
                " fill-current w-6 h-6"
              }
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
            </svg>

            <svg
              className={
                (darkIcon === "sun" ? "swap-on" : "swap-off") +
                " fill-current w-6 h-6"
              }
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
            </svg>
          </label>
        </div>
        {/* <!-- Page content here --> */}
        {children}
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <ul className="menu p-4 overflow-y-auto w-80 bg-base-100">
          {/* <!-- Sidebar content here --> */}
          <li>
            <a onClick={gotoHourly}>Hourly</a>
          </li>
          <li>
            <a onClick={gotoDaily}>7-Day</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
